import BaseModel from './base'
import type { _BaseType } from './_base_type'

export type NewsLetterType = _BaseType & {
  email: string
}

export class NewsLetterModel extends BaseModel {
  constructor() {
    super('newsLetters')
    this.uniques = [['_ownerId']]
    this.required = ['email' ]
  }
}
